import type { payloadFilter } from '@/types/searchItemsTypes'
import type { arrayOfFilters } from '@/types/types';


export function transformFilters(data: arrayOfFilters) {
  const filters: payloadFilter[] = [];
  if (data) {
    data.forEach(item => {
      const filterType = item.options.label;
      const values = item.options.values || [];

      if (item.type === 'GRAPH_SELECT' && item.options.max_filter_value && item.options.min_filter_value) {
        filters.push({
          filter_type: filterType,
          values: {
            lte: item.options.max_filter_value,
            gte: item.options.min_filter_value
          }
        });
      } else {
        const selectedValues = values
          .filter((v: any) => v.isSelected)
          .map((v: any) => v.name);
        filters.push({
          filter_type: filterType,
          values: selectedValues
        });
      }
    })
  }

  return filters.filter((v) => {
    if (Array.isArray(v.values)) {
      return v.values.length > 0;
    } else if (typeof v.values === 'object') {
      return v.values.lte !== undefined || v.values.gte !== undefined;
    }
    return false;
  });
}
