<template>
  <div class="testyObject">
    <Button
      @click.stop="toggle"
      :icon="`pi ${op?.visible ? 'pi-times' : 'pi-plus'}`"
      severity="help"
      :pt="{
        root: {
          style: {
            backgroundColor: 'rgba(216, 229, 173, 0.8)',
            height: '48px',
            width: '48px',
            borderRadius: '50%',
            padding: '0',
          },
        },
      }"
    />

    <Popover ref="op" class="testOp" appendTo="body"
      :pt="{
        root:{
          style:{
            padding: '0',
            borderRadius:'34px',
          }
        },
        content:{
          style:{
            padding:'12px',
          }
        }
      }"
    >
      <div class="ai-tip-capsules-tip">
        <slot></slot>
      </div>
    </Popover>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import Popover from "primevue/popover";
import { $dt } from '@primevue/themes';
import { ref } from "vue";

const op = ref();
const members = ref([
  { name: "Amy Elsner", image: "amyelsner.png", email: "amy@email.com", role: "Owner" },
  {
    name: "Bernardo Dominic",
    image: "bernardodominic.png",
    email: "bernardo@email.com",
    role: "Editor",
  },
  {
    name: "Ioni Bowcher",
    image: "ionibowcher.png",
    email: "ioni@email.com",
    role: "Viewer",
  },
]);

const toggle = (event: Event) => {
  op.value.toggle(event);
  console.log(op.value);
  console.log($dt('popover'))
};
</script>

<style scoped>
:deep(.testOp) {
  top: 50% !important;
  left: 50% !important ;
  transform: translate(0, 15%);
}


:deep(.testOp:before) {
  content:none !important;
}

:deep(.testOp:after) {
  content:none !important;
}

.testyObject {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000;
}

.ai-tip-capsules-text {
  width: 320px;
  color: rgba(110, 133, 203, 1);
}
</style>

<style>
.testOp:before {
  content:none !important;
}

.testOp:after {
  content:none !important;
}

</style>
