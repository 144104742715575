<script setup lang="ts">
import Drawer from "primevue/drawer";
import Divider from "primevue/divider";
import Button from "primevue/button";
import BlockUI from "primevue/blockui";
import { computed, ref } from "vue";
import SearchPageSideMenuFiltersItemsWrapper from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/SearchPageSideMenuFiltersItemsWrapper.vue";
import SearchPageSuggestedFilters from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/SearchPageSuggestedFilters.vue";
import SearchPageSelectedFilters from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/SearchPageSelectedFilters.vue";
import { useSearch } from "@/stores/search";
import SearchPageSortionOptions from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/SearchPageSortionOptions.vue";
import SearchPageSuggestedFiltersSkeleton from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/SearchPageSuggestedFiltersSkeleton.vue";
import SearchPageSelectedFiltersSkeleton from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/SearchPageSelectedFiltersSkeleton.vue";
import CustomSpinnerBlockUI from "@/blocks/SearchPage/SearchPageContent/SearchPageContentFiltresWrapper/CustomSpinnerBlockUI.vue";

const search = useSearch();

const visible = ref(false);

const isSearchPageDataLoading = computed(() => search.isGetSearchListLoading);
const suggestedFilters = computed(() => search.suggestedFilters);
const filters_quantity = computed(() =>
  search.currentFilters?.reduce((accumulator, currentValue) => {
    if (!currentValue.options.values) return accumulator;
    return (
      accumulator +
      currentValue.options.values?.reduce((subAccumulator, currentValue) => {
        if (currentValue.isSelected) {
          return subAccumulator + 1;
        } else {
          return subAccumulator;
        }
      }, 0)
    );
  }, 0)
);

function handleSuggestedFiltersClick(item: any) {
  if (item) search.setCheckBoxValueState(item.id, true);
}
</script>

<template>
  <div class="search-filters__wrapper">
    <Drawer
      v-model:visible="visible"
      class="scrollbar-styler"
      header="Все фильтры"
      :pt="{
        root: {
          style: {
            width: '400px',
          },
        },
        header: {
          style: {
            color: 'rgba(61, 61, 61, 1)',
          },
        },
      }"
    >
      <CustomSpinnerBlockUI v-if="isSearchPageDataLoading" />
      <search-page-side-menu-filters-items-wrapper v-model="visible" />
    </Drawer>
    <div class="search-filters--inner">
      <div class="search-filters--inner-top">
        <Button
          icon="pi pi-sliders-h"
          :label="`Все фильтры (${filters_quantity})`"
          icon-pos="left"
          @click="visible = true"
          :pt="{
            root: {
              style: {
                minWidth: '160px',
              },
            },
            label: {
              style: {
                textWrap: 'nowrap',
              },
            },
          }"
        />
        <search-page-suggested-filters-skeleton v-if="isSearchPageDataLoading" />

        <div v-else class="wrapper-filters-and-options">
          <search-page-suggested-filters
            :suggested-filters="suggestedFilters"
            @suggested-filter-click="handleSuggestedFiltersClick"
          />
          <search-page-sortion-options />
        </div>
      </div>

      <Divider />

      <div class="search-filters--inner-bottom">
        <search-page-selected-filters-skeleton v-if="isSearchPageDataLoading" />
        <search-page-selected-filters v-else />
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-filters__wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 0;
}

.search-filters--inner-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.wrapper-filters-and-options {
  display: flex;
  width: 84%;
  justify-content: space-between;
}
</style>

<style>
.p-drawer-content {
  scrollbar-gutter: stable;
}

.p-drawer-content::-webkit-scrollbar {
  width: 4px; /* Ширина всего элемента навигации */
}

.p-drawer-content::-webkit-scrollbar-track {
  background: transparent; /* Цвет дорожки */
}

.p-drawer-content::-webkit-scrollbar-thumb {
  background-color: rgba(145, 145, 145, 1); /* Цвет бегунка */
  border-radius: 100px; /* Округление бегунка */
  border: 1px solid rgba(145, 145, 145, 1); /* Оформление границ бегунка */
}

.search-filters--inner {
  width: 100%;
  max-width: 100%;
}
</style>
