<script setup lang="ts">
import {
  Autoplay,
  Controller,
  FreeMode,
  Navigation,
  Virtual,
  Pagination,
} from "swiper/modules";
import { onMounted, ref } from "vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
import Button from "primevue/button";
import Control from "@/components/UI/controllSwiper/control.vue";
import { getPopularBrands } from "@/api/brands";

import AiTipForCapsules from "./AiTipForCapsules.vue";
import CapsulesAiTip from "./CapsulesAiTip.vue";
import BrandsSkeleton from "@/blocks/HomeWrapper/PopularBrands/BrandsSkeleton.vue";
import DeferredContent from "primevue/deferredcontent";
import YellowAiTip from "@/blocks/SharedBlocks/YellowAiTip.vue";

import dress from '@/assets/TempImagesForDeleteCapsules/dress.svg'
import palto from '@/assets/TempImagesForDeleteCapsules/palto.svg'
import glasses from '@/assets/TempImagesForDeleteCapsules/glasses.svg'
import secondGlasses from '@/assets/TempImagesForDeleteCapsules/secondGlasses.svg'
import sumka from '@/assets/TempImagesForDeleteCapsules/sumka.svg'
import tufli from '@/assets/TempImagesForDeleteCapsules/tufli.svg'

const products = [
  {
    name: "Пальто",
    image: dress,
  },
  {
    name: "Куртка",
    image: palto,
  },
  {
    name: "Шапка",
    image: glasses,
  },
  {
    name: "Очки",
    image: secondGlasses,
  },
  {
    name: "Сумка",
    image: sumka,
  },
  {
    name: "Туфли",
    image: tufli,
  },
];

//old
const swiperModules = [FreeMode, Autoplay, Navigation, Controller, Virtual, Pagination];
const brands = ref();

const isBrandsLoading = ref(false);

async function loadBrandsData() {
  isBrandsLoading.value = true;

  const res = await getPopularBrands({
    page_num: 1,
    page_size: 16,
  });
  brands.value = res.data.brands;

  isBrandsLoading.value = false;
}
</script>

<template>
  <DeferredContent @load="loadBrandsData">
    <div class="popular-brands__wrapper">
      <brands-skeleton v-if="isBrandsLoading" />
      <div v-else class="swp-wrp">
        <swiper
          :free-mode="true"
          :space-between="20"
          :slides-per-view="3.5"
          :slidesPerGroup="3.5"
          :breakpoints="{
            480: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 2,
            },
            1440: {
              slidesPerView: 3.5,
            },
            1920: {
              slidesPerView: 3.5,
            },
          }"
          :modules="swiperModules"
          :pagination="{
            clickable: true,
          }"
          class="company-carousel__container"
        >
          <template #container-start>
            <div class="popular-brands__header">
              <div class="popular-brands__header-title">
                <span class="popular-brands__title">Capsules</span>
                <yellow-ai-tip ai-tip-text="products that are easy to combine" />
              </div>
              <control :control-color="'rgba(35, 47, 69, 1)'" />
            </div>
          </template>
          <swiper-slide
            v-for="(company, index) in brands"
            :key="index"
            class="company-carousel__item"
          >
            <div class="capsules-card-wrapper">
              <div class="capsulest-card-header-actions-buttons">
                <Button
                  icon="pi pi-thumbs-down"
                  text
                  severity="primary"
                  :pt="{
                    root: {
                      style: {
                        height: '48px',
                        width: '48px',
                        borderRadius: '50%',
                        padding: '0',
                      },
                    },
                  }"
                />

                <Button
                  icon="pi pi-heart"
                  severity="help"
                  :pt="{
                    root: {
                      style: {
                        height: '48px',
                        width: '48px',
                        borderRadius: '50%',
                        padding: '0',
                        background: 'rgba(255, 255, 255, 0.8)',
                      },
                    },
                  }"
                />
              </div>
              <div class="capsulest-card-main-content">
                <div
                  :key="productItem.name"
                  v-for="(productItem, index) in products"
                  class="capsules-image"
                  :style="{
                    'z-index': index,
                    backgroundImage: `url(${productItem.image})`,
                  }"
                  :alt="productItem.name"
                >
                  <ai-tip-for-capsules>
                    <CapsulesAiTip
                      brand="Wildberries"
                      title="Long silk evening dress..."
                      :price="39"
                      :image="productItem.image"
                    />
                  </ai-tip-for-capsules>
                </div>
              </div>
              <div class="capsulest-card-footer">
                <span class="capsulest-card-footer-title"
                  >Workday Essentials Capsule</span
                >
                <span class="capsulest-card-footer-description"
                  >Polished pieces to power through the week with confidence.</span
                >
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </DeferredContent>
</template>

<style scoped>
:deep(.popular-brands__header) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 220px 60px 0;
}

.popular-brands__header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.popular-brands__title {
  font-size: 48px;
  line-height: 48px;
  line-height: 48px;
  color: rgba(35, 47, 69, 1);
}

.popular-brands__wrapper {
  display: flex;
  padding-left: 100px;
  flex-direction: column;
  min-height: 670px;
  width: 100%;
  justify-content: center;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.capsules-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 640px;
  width: 100%;
  border-top-right-radius: 30px;
  overflow: hidden;
}

.capsulest-card-header-actions-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 96%;
  top: 6px;
  z-index: 10;
}

.capsulest-card-main-content {
  height: 500px;
  width: 100%;
  position: relative;
  background-image: url("/src/assets/img/BG.svg");
  background-repeat: no-repeat;
}

.logo-brand {
  width: 100%;
}

.adv-images {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
}

.logo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 35%;
  position: absolute;
}

.capsules-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
}

.capsules-image:first-child {
  top: 10%;
  left: 2%;
  height: 90%;
  width: 46%;
  background-repeat: no-repeat;
}

.capsules-image:nth-child(2) {
  top: 10%;
  right: 2%;
  height: 90%;
  width: 46%;
  background-repeat: no-repeat;
}

.capsules-image:nth-child(3) {
  top: 12%;
  left: 22%;
  height: 20%;
  width: 20%;
}

.capsules-image:nth-child(4) {
  top: 12%;
  right: 22%;
  height: 20%;
  width: 20%;
}

.capsules-image:nth-child(5) {
  bottom: 5%;
  left: 16%;
  height: 24%;
  width: 30%;
}

.capsules-image:nth-child(6) {
  bottom: 5%;
  right: 16%;
  height: 24%;
  width: 30%;
}

.img-on-capsule {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.capsulest-card-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.capsulest-card-footer-title {
  font-size: 1.75rem;
  color: rgba(35, 47, 69, 1);
  font-weight: 500;
}

.capsulest-card-footer-description {
  font-size: 1rem;
  color: rgba(35, 47, 69, 0.6);
}

:deep(.swiper-pagination-bullet) {
  width: 24px;
  height: 4px;
  border-radius: 100px;
}
</style>

<style>
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
