import { ref } from 'vue'
import { defineStore } from 'pinia'
import axiosApiInstance, { updateTokens } from '../api/api'
import { useAuthModal } from '@/stores/authModal'

export const useAuthStore = defineStore('auth', () => {
  const userInfo = ref({
    accessToken: null,
    refreshToken: null,
    expiresIn: null as number | null,
    refresh_expires_in: null as number | null,
    name: null,
    email: null,
    tempEmailFormPasswordRestore: null
  })

  let refreshTimeout: ReturnType<typeof setTimeout> | null = null;

  const userTokens = localStorage.getItem('userTokens');
  if (userTokens) {
    const parsedTokens = JSON.parse(userTokens);
    userInfo.value.accessToken = parsedTokens?.accessToken;
    userInfo.value.refreshToken = parsedTokens?.refreshToken;
    userInfo.value.expiresIn = parsedTokens?.expiresIn;
    userInfo.value.refresh_expires_in = parsedTokens?.refresh_expires_in;
  }

  const error = ref('')
  const loader = ref(false)


  function scheduleTokenRefresh() {

    // Очистим предыдущий таймер, если он есть
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }

    if(userInfo.value.refresh_expires_in) {
      const timeUntilExpiration = userInfo.value.refresh_expires_in - Date.now() - 60 * 1000 // Обновляем за 1 минуту до истечения

      if (timeUntilExpiration > 0) {
        refreshTimeout = setTimeout(async () => {
          await updateTokens(userInfo);
          scheduleTokenRefresh(); // Перепланируем обновление токенов
        }, timeUntilExpiration);
      }
    }
  }


  const getUserInfo = async () => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/user-info`, {
        headers: {
          Authorization: `Bearer ${userInfo.value.accessToken}`
        }
      })
      if (response) {
        scheduleTokenRefresh()
        userInfo.value.name = response.data.name
        userInfo.value.email = response.data.email
        useAuthModal().hideAuthModal()
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  function setUserTokens(response) {
    userInfo.value = {
      accessToken: response.data.access_token,
      expiresIn: response.data.expires_in * 1000 + Date.now(),
      refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
      refreshToken: response.data.refresh_token
    }
    localStorage.setItem(
      'userTokens',
      JSON.stringify({
        accessToken: response.data.access_token,
        expiresIn: response.data.expires_in * 1000 + Date.now(),
        refresh_expires_in: response.data.refresh_expires_in * 1000 + Date.now(),
        refreshToken: response.data.refresh_token
      })
    )
  }

  const auth = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/login`, {
        ...payload
      })

      if(response.data) {
        setUserTokens(response)
      }
      return response
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const thirdPartyAuth = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/login-with-third-party/${payload}`)
      if (response) {
        window.open(response.data.auth_uri)
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const exchangeCodeForTokens = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/exchange-code-for-tokens/?code=${payload}`)
      if (response.data) {
        setUserTokens(response)
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const forgotPassword = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/forgot-password`, {
        email: payload
      })
      if (response) {
        userInfo.value.tempEmailFormPasswordRestore = payload
        useAuthModal().showAuthModal('checkEmailResetPassword')
      }
    } catch (err) {
      throw error.value
    } finally {
      loader.value = false
    }
  }

  const signUp = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/signup`, {
        ...payload
      })

      useAuthModal().showAuthModal('verifyUser')
    } catch (err) {
      return err
    } finally {
      loader.value = false
    }
  }

  const verifyUser = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.get(`/auth/verify-email?token=${payload}`)
      if (response.data) {
        setUserTokens(response)
      }

    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const resetPassword = async (payload) => {
    error.value = ''
    loader.value = true
    try {
      let response = await axiosApiInstance.post(`/auth/reset-password`, {
        token: payload.token,
        password: payload.password
      })

      if (response) {
        setUserTokens(response)
      }
    } catch (err) {
      throw err
    } finally {
      loader.value = false
    }
  }

  const logout = () => {
    localStorage.removeItem('userTokens')
    userInfo.value = {
      accessToken: null,
      email: null,
      name: null,
      refreshToken: null,
      expiresIn: null,
      refresh_expires_in: null,
      tempEmailFormPasswordRestore: null
    }
  }

  return {
    auth,
    userInfo,
    error,
    loader,
    logout,
    signUp,
    forgotPassword,
    verifyUser,
    resetPassword,
    thirdPartyAuth,
    exchangeCodeForTokens,
    getUserInfo,
    refreshTimeout
  }
})
