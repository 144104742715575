<script setup lang="ts">
import { ref, watch, computed } from "vue";

import { autocomplete, deleteSearchHistory, searchHistory } from "@/api/search";

import router from "@/router";
import Textarea from "primevue/textarea";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Button from "primevue/button";
import type { SuggestedBrand, RecentSearch } from "@/types/searchItemsTypes";
import { useSearch } from "@/stores/search";
import { useImageSearch } from "@/stores/imageSearch";

const searchStore = useSearch();
const imageSearch = useImageSearch();

const clipPath = ref(
  "path('M900 30C900 13.4315 886.569 0 870 0H30C13.4315 0 0 13.4315 0 30V110C0 126.569 13.4315 140 30 140H1058C1074.57 140 1088 126.569 1088 110V97C1088 80.4315 1074.57 67 1058 67H930C913.431 67 900 53.5685 900 37V30Z')"
);

const searchedText = ref("");

const searchHistoryItems = ref<RecentSearch[]>([]);
const typeOfSearchHistory = ref("text");

const autocompleteItems = ref<string[]>([]);
const autocompleteBrandsItems = ref<SuggestedBrand[]>([]);
const autocompleteAiTip = ref("same dress but ...");

const historyItemsForDelete = ref<string[]>([]);

const highlightedAiTipText = computed(() => {
  if (!searchedText.value.trim()) {
    return autocompleteAiTip.value; // Если ничего не введено, показываем обычный текст
  }

  // Создаем регулярное выражение для поиска
  const queryRegex = new RegExp(`(${searchedText.value})`, "gi");

  // Заменяем совпадения на выделенные
  return autocompleteAiTip.value.replace(
    queryRegex,
    '<strong class="highlight">$1</strong>'
  );
});

async function getAutocomplete(event: Event) {
  const response = await autocomplete({
    search_text: searchedText.value,
    suggestions_size: 5,
    suggested_brands_size: 5,
  });
  autocompleteItems.value = response.data.suggestions;
  autocompleteBrandsItems.value = response.data.suggested_brands;
}

function deleteHistoryItem(idOfHistoryItem: string) {
  historyItemsForDelete.value.push(idOfHistoryItem);
}

function deleteAllHistoryItems() {
  historyItemsForDelete.value = searchHistoryItems.value.map((item) => item.id);
}

function deleteImageFromSearch() {
  imageSearch.currentImageLink = "";
  imageSearch.imageBase64Data = null;
  imageSearch.imageFile = undefined;
}

async function getSearchHistory(size: number, type: string) {
  const response = await searchHistory(5, type);
  if (response.data.recent_searches)
    searchHistoryItems.value = response.data.recent_searches;
}

async function startSearchFlow() {
  await searchStore.defineFilters(String(searchedText.value));
  await router.push(`/searchList/${searchedText.value}`);
}

watch(
  historyItemsForDelete,
  async () => {
    const res = await deleteSearchHistory(historyItemsForDelete.value);
  },
  {
    deep: true,
  }
);
</script>

<template>
  <div class="search__wrapper">
    <IconField :pt="{ root: { style: { width: '100%' } } }">
      <InputIcon :pt="{ root: { style: { top: '20px' } } }" class="pi pi-search" />
      <Textarea
        v-model="searchedText"
        @input="getAutocomplete"
        @focus="getSearchHistory(5, 'text')"
        rows="7"
        placeholder="Type here what you want to find or describe what to change in your photo"
        :pt="{
          root: {
            style: {
              width: '100%',
              paddingLeft: '30px',
              borderRadius: '28px',
              boxShadow: '0px 0px 0px 3px #BFDBFE',
            },
          },
        }"
      />
    </IconField>
    <div v-if="autocompleteItems.length" class="search-autocomplete-inner">
      <div class="search-autocomplete-ai-tip">
        <i class="pi pi-info-circle"></i>
        <p v-html="highlightedAiTipText" class="text"></p>
      </div>

      <ul class="search-autocomplete-text-tip">
        <li
          v-for="item in autocompleteItems"
          @click="searchedText = item"
          class="text-tip"
        >
          {{ item }}
        </li>
      </ul>

      <div v-if="autocompleteBrandsItems.length" class="search-autocomplete-brands-tip">
        <span class="brands-title">Бренды</span>
        <div class="brands-items-list__wrapper">
          <div v-for="brandItem in autocompleteBrandsItems" class="brands-item">
            <img
              :src="`https://static.fair.store/${brandItem?.logo_url}`"
              class="brand-image"
              alt="brand-image"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="searchHistoryItems.length && !autocompleteBrandsItems.length"
      class="search-history-inner"
    >
      <div class="history-title__wrapper">
        <span class="history-title">Search history</span>
        <span class="clear-history-btn" @click.stop="deleteAllHistoryItems"> Clear </span>
      </div>

      <ul class="search-history-text-tip">
        <li
          v-for="item in searchHistoryItems"
          @click="searchedText = item.query"
          class="text-tip"
        >
          {{ item.query }}
          <i
            class="pi pi-times"
            @click.stop="deleteHistoryItem(item.id)"
            style="color: rgba(145, 145, 145, 1)"
          />
        </li>
      </ul>
    </div>

    <div class="search-buttons-wrapper">
      <Button
        @click="imageSearch.isImageSearchModalOpen = true"
        :pt="{
          root: {
            style: {
              width: '100%',
              height: '140px',
              clipPath: clipPath,
              background:
                'linear-gradient(rgba(186, 174, 206, 0.3), rgba(110, 133, 203, 0.3))',
              border: 'none',
              color: 'rgba(35, 47, 69, 1)',
              fontSize: '42px',
              justifyContent: 'flex-start',
              padding: '10px',
            },
          },
          label: {
            style: {
              maxWidth: '100px',
              textAlign: 'left',
            },
          },
        }"
      >
        <div v-if="imageSearch?.imageBase64Data" class="uploaded-image-inside-wrapper">
          <img :src="imageSearch?.imageBase64Data" class="uploaded-image" />
          <div class="image-buttons-wrapper">
            <Button
              icon="pi pi-pencil"
              severity="help"
              :pt="{
                root: {
                  style: {
                    height: '48px',
                    width: '48px',
                    borderRadius: '50%',
                    padding: '0',
                  },
                },
              }"
            />

            <Button
              @click.stop="deleteImageFromSearch"
              icon="pi pi-times"
              severity="help"
              :pt="{
                root: {
                  style: {
                    height: '48px',
                    width: '48px',
                    borderRadius: '50%',
                    padding: '0',
                  },
                },
              }"
            />
          </div>
        </div>

        <div v-else class="uploaded-image-inside-wrapper">
          <div class="add-photo-title-text">Add photo</div>
          <Button
            text
            icon="pi pi-plus"
            :pt="{
              root: {
                style: {
                  height: '48px',
                  width: '48px',
                  borderRadius: '50%',
                  padding: '0',
                },
              },
            }"
          />
        </div>
      </Button>
      <Button
        @click="startSearchFlow"
        iconPos="right"
        icon="pi pi-search"
        label="search"
        :pt="{
          root: {
            style: {
              position: 'absolute',
              top: '0',
              right: '0',
              maxWidth: '180px',
              width: '180px',
              height: '60px',
            },
          },
        }"
      />
    </div>
  </div>
</template>

<style scoped>
.search__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 1112px;
  position: absolute;
  top: 200px;
  z-index: 7;
  padding: 12px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 36px;
}

.search_text__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.search_text__wrapper .title {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  color: rgba(255, 255, 255, 1);
}

.search_text__wrapper .subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(145, 145, 145, 1);
}

.wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.search-buttons-wrapper {
  width: 100%;
  position: relative;
}

.search-autocomplete-inner {
  width: 100%;
  padding: 12px 8px;
}

.search-history-inner {
  width: 100%;
  padding: 12px 8px;
}

.search-autocomplete-ai-tip {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  color: rgba(110, 133, 203, 1);
  padding: 8px 12px;
  background: rgba(216, 229, 173, 0.8);
  border-radius: 28px;
  width: 100%;
}

.search-autocomplete-text-tip {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  list-style: none;
  padding: 0;
}

.search-autocomplete-text-tip li {
  padding: 8px 12px;
  background: white;
  border-radius: 28px;
  cursor: pointer;
}

.search-history-text-tip {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  list-style: none;
  padding: 0;
}

.search-history-text-tip li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: white;
  border-radius: 28px;
  cursor: pointer;
}

.search-history-text-tip li:active {
  background: rgba(0, 0, 0, 0.1);
}

.search-autocomplete-brands-tip {
  width: 100%;
}

.brands-tips {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 0 8px 12px;
}

.brands-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(26, 33, 51, 1);
}

.history-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(26, 33, 51, 1);
}

.brands-items-list__wrapper {
  display: flex;
  gap: 24px;
}

.brands-item {
  height: 96px;
  width: 96px;
  padding: 8px 12px 8px 12px;
  background: rgba(215, 217, 219, 1);
  border-radius: 20px;
}

.brand-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}

.text {
  padding: 0;
  margin: 0;
}

:deep(.highlight) {
  font-weight: 600 !important;
}

.clear-history-btn {
  font-size: 19px;
  font-weight: 500;
  color: rgba(35, 47, 69, 0.6);
}

.uploaded-image {
  width: 176px;
  height: 116px;
  border-radius: 24px;
}

.uploaded-image-inside-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.image-buttons-wrapper {
  display: flex;
  gap: 8px;
  justify-content: space-around;
}

.add-photo-title-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 170px;
  text-align: start;
  padding: 10px;
}
</style>
