<script setup lang="ts">
import Button from "primevue/button";
import Popover from "primevue/popover";
import type { FilterItem } from "@/types/types";
import { computed, ref } from "vue";
import MyMultiSelect from "@/blocks/MultiSelect/MyMultiSelect.vue";
import { useSearch } from "@/stores/search";

const { data } = defineProps<{
  data: FilterItem;
  appendTo: string;
}>();

const search = useSearch();

const label = computed(() => {
  const resalut = data.options.values
    ?.filter((item) => item.isSelected)
    .map((item) => item.name)
    .join(", ");
  if (resalut) {
    return resalut;
  } else {
    return data.options.label;
  }
});

const op = ref();

function clearSelectedFilter() {
  search.clearFiltersToSelectedFilterType(data);
}

const toggle = (event) => {
  op.value.toggle(event);
};
</script>

<template>
  <div class="av">
    <Button
      severity="help"
      @click="toggle"
      :pt="{
        root: {
          style: {
            color: 'rgba(110, 133, 203, 1)',
            border: 'none',
          },
        },
      }"
    >
      <span>{{ label }}</span>
      <i class="pi pi-times" @click.stop="clearSelectedFilter"></i>
    </Button>
    <Popover
      ref="op"
      :append-to="appendTo"
      :pt="{
        content: {
          style: {
            padding: '0',
          },
        },
      }"
    >
      <MyMultiSelect :data="data.options" />
    </Popover>
  </div>
</template>

<style scoped></style>
