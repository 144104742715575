import type { arrayOfFilters, FilterItemOptions } from "@/types/types";


interface Obj1 {
  [key: string]: string[];
}

export function updateObjects(obj1: Obj1, obj2: arrayOfFilters): arrayOfFilters {
  if (obj2)
    obj2.forEach(item => {
      const label = item.options.label;

      if (label in obj1) {
        const obj1Values = obj1[label];
        if (item.type === 'GRAPH_SELECT') {
          item.options.min_filter_value = Number(obj1[item.options.label][0]);
          item.options.max_filter_value = Number(obj1[item.options.label][1]);
        }

        if (item.type === 'MULTI_SELECT' || item.type === 'COLOR_SELECT') {
          if (item.options.values) item.options.values.forEach(value => {
            if (obj1Values.includes(value.name)) {
              value.isSelected = true;
            }
          });
        }
      }
    });

  return obj2;
}